import { FC, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { LatLng } from "leaflet";
import Incident from '../../models/incident.model';
import ConfigService from '../../config/config.service';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import exifr from 'exifr';
import { useGeolocated } from "react-geolocated";


const config = ConfigService();

interface CreateIncidentProps {
    position: LatLng;
    setRefreshIncidents: (e: any) => void;
    addMarker: (e: any) => void;
    removeMarker: () => void;
    setLocation: (e: any) => void;
    setMenu: (e: any) => void;
    setPosition: (e: any) => void;
}

const CreateIncident: FC<CreateIncidentProps> = ({ position, setRefreshIncidents,
    addMarker, removeMarker, setLocation, setMenu, setPosition }) => {
    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [incidentFile, setIncidentFile] = useState([]);
    const [keys, setKeys] = useState([]);
    const [type, setType] = useState("");
    console.log('cofnig', config);
    const { coords, isGeolocationAvailable, isGeolocationEnabled, positionError } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    useEffect(() => {
        console.log('isGeolocationAvailable: ', isGeolocationAvailable);
        console.log('isGeolocationEnabled: ', isGeolocationEnabled);
        console.log('positionError:', positionError);

        if (coords) {
            setLocation({
                latitude: coords.latitude,
                longitude: coords.longitude
            });
            setPosition(new LatLng(coords.latitude, coords.longitude));
        }
    }, [coords, isGeolocationAvailable, isGeolocationEnabled, positionError]);

    const { t } = useTranslation();

    const getAllKeys = async () => {
        const response = await axios.get(config.KEYS_ENDPOINT);
        return response.data;
    }

    useEffect(() => {
        getAllKeys().then(keys => setKeys(keys));
    }, []);

    const handleClick = async (event: any) => {
        console.log(`${title} - ${description} - ${position.lat} - ${position.lng}`);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            console.log('error');
        } else {
            event.preventDefault();

            let bodyFormData = new FormData();
            const ins = incidentFile.length;
            let fileNames = [];

            for (let x = 0; x < ins; x++) {
                bodyFormData.append("incidentFile", incidentFile[x]);
                fileNames[x] = incidentFile[x].name;
            }


            console.log('filenames: ', fileNames);

            const responseFile = await axios.post(config.IMAGES_ENDPOINT,
                bodyFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } });
            console.log('response file: ', responseFile);

            const incident = new Incident(title, description, description, position.lat, position.lng,
                new Date(), responseFile.data.filename, type, fileNames, null, null, null,
                null, null, null, null);
            console.log('incident', incident);
            const response = await axios.post(config.INCIDENTS_ENDPOINT, incident);
            setTitle("");
            setDescription("");
            setRefreshIncidents(true);
            removeMarker();
            setMenu("thank");
        }
        setValidated(true);
    };

    const handleFileChange = (e: any) => {
        if (e.target.files !== null) {
            setIncidentFile(e.target.files);
            if (e.target.files[0] !== null) {
                exifr.gps(e.target.files[0]).then((gps: any) => {
                    console.log(gps);
                    if (gps != null) {
                        setLocation(gps);
                    }
                });
            }
        }

    }

    const setLatitude = (lat: any) => {
        if (!isNaN(lat)) {
            let pos = position.clone();
            pos.lat = lat;
            console.log('lat: ', pos);
            setPosition(pos);
            setLocation({
                latitude: pos.lat,
                longitude: pos.lng
            });
        }
    }

    const setLongitude = (lng: any) => {
        if (!isNaN(lng)) {
            let pos = position.clone();
            pos.lng = lng;
            console.log('lng: ', pos);
            setPosition(pos);
            setLocation({
                latitude: pos.lat,
                longitude: pos.lng
            });
        }
    }

    return (

        <Container style={{ color: '#fff' }}>
            <Row>
                <Col>{t('title')}</Col>
            </Row>
            <Form id="createIncident" encType={'multipart/form-data'} noValidate validated={validated}
                onSubmit={handleClick}
            >
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.create.title')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Control required type="text" placeholder="Enter title" onChange={(e) => setTitle(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.titleError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        {t('incidents.create.description')}
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Control as="textarea" required rows={3} onChange={(e) => setDescription(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.descriptionError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        {t('incidents.create.type')}
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Select required onChange={(e) => { console.log(e.target.selectedIndex); setType(keys[e.target.selectedIndex - 1].identifier); }}>
                            <option></option>
                            {
                                keys && keys.map((key: any) =>
                                    <option id={key.identifier} key={key.identifier}>{key.name}</option>
                                )
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.typeError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Control required type="file" onChange={handleFileChange} multiple />
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.fileError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {
                    config.ADD_POSITION_ENABLED === "true" && <Row>
                        <Col>
                            <Form.Group as={Col} md="12" controlId="validationCustom05">
                                <Form.Control required type="text"
                                    pattern="^-?([0-8]?[0-9]|90)((\.|\,)[0-9]{1,10})$"
                                    placeholder="Enter latitude"
                                    onChange={(e) => setLatitude(e.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    {t('incidents.create.latitudeError')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                }
                {
                    config.ADD_POSITION_ENABLED === "true" && <Row>
                        <Col>
                            <Form.Group as={Col} md="12" controlId="validationCustom06">
                                <Form.Control required type="text"
                                    pattern="^-?([0-8]?[0-9]|90)((\.|\,)[0-9]{1,10})$"
                                    placeholder="Enter longitude"
                                    onChange={(e) => setLongitude(e.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    {t('incidents.create.longitudeError')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                }
                <Row>
                    <Col>
                        <Button variant="primary" type="submit">
                            {'Submit'}
                        </Button>
                        <input type="hidden" id="lat" value={position.lat} />
                        <input type="hidden" id="lng" value={position.lng} />
                    </Col>
                    {config.ADD_MARKER_ENABLED === "true" && <Col>
                        <Button className="addmarker"
                            variant="primary"
                            onClick={addMarker}
                        >
                            {'Add Marker'}
                        </Button>
                    </Col>
                    }
                </Row>
            </Form>
            {position.lat} - {position.lng}

        </Container >

    )

}

//onChange={(e) => setIncidentFile(e.target.value)}
export default CreateIncident;
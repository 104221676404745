import { FC, useState, useEffect } from 'react';
import ConfigService from '../../config/config.service';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Incident from '../../models/incident.model';
import IncidentModal from './incident.modal.component';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


import './incidents.css';

const config = ConfigService();

interface IncidentsProps {
    incidents: Incident[],
    incidentId: string
    setFilter: (e: any) => void;
}

const IncidentsList: FC<IncidentsProps> = ({ incidents, incidentId, setFilter }) => {
    const [filteredIncidents, setFilteredIncidents] = useState<Incident[]>([]);
    const { t, i18n } = useTranslation();

    let d = new Date();
    d.setMonth(d.getMonth() - 1);


    const [modalIsOpen, setIsOpen] = useState(false);
    const [incidentObject, setIncident] = useState(new Incident(null, null, null, null,
        null, null, null, null, null, null, null, null, null, null, null, null));

    useEffect(() => {
        setFilteredIncidents(incidents);
    }, [incidents]);

    const isNotImage = (fileName: string) => {
        if (fileName.indexOf(".doc") != -1 || fileName.indexOf(".xls") != -1
            || fileName.indexOf(".docx") != -1 || fileName.indexOf(".xlsx") != -1
            || fileName.indexOf(".ppt") != -1 || fileName.indexOf(".pptx") != -1
            || fileName.indexOf(".pdf") != -1 || fileName.indexOf(".csv") != -1
            || fileName.indexOf(".odp") != -1 || fileName.indexOf(".ods") != -1
            || fileName.indexOf(".odt") != -1) {
            return true;
        }
        return false;
    }

    const setOpen = (isOpen: boolean) => {
        setIsOpen(isOpen);
    }

    const filterIncidents = (search: string) => {
        console.log('filterincidents: ', search);
        if (search !== '') {
            let searchArray = incidents.filter(
                a => a.lat.toString().indexOf(search) !== -1
                    || a.lng.toString().indexOf(search) !== -1
                    || a.title.toLowerCase().indexOf(search) !== -1
                    || (a.drones?.toLowerCase().indexOf(search) !== -1 && typeof a.drones != 'undefined')
                    || a.getDescription(i18n.language).toLowerCase().indexOf(search) !== -1);
            setFilteredIncidents(searchArray);
            setFilter(searchArray);
        } else {
            setFilteredIncidents(incidents);
        }

    }

    return (
        <Container fluid>
            <Row>
                <Col md={12} >
                    <Form.Control type="text" placeholder={t('incidents.list.search')}
                        onChange={(s) => filterIncidents(s.target.value.toLowerCase())}
                        style={{
                            textDecorationColor: '#5F7749',
                            backgroundColor: '#000',
                            color: '#ECECEC'
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col md={12} style={{
                    backgroundColor: '#000',
                    color: '#000',
                    background: '#000'
                }}>
                    <br />
                    <IncidentModal
                        incident={incidentObject}
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen} />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <div className="div-scroll">
                        {filteredIncidents.map((incident: Incident, i: any) => {
                            if (incidentId === '' || incident._id === incidentId) {
                                {/*<div key={`incident_${i}`} > */ }
                                return <Table bordered color={'#fff'} style={{
                                    color: '#fff', textDecorationColor: '#fff',
                                    borderWidth: 3
                                }}>
                                    <tbody>
                                        <tr>
                                            <Table bordered={false} color={'#fff'}
                                                style={{
                                                    color: '#fff',
                                                    textDecorationColor: '#fff', borderColor: '#000',
                                                }}>
                                                <tbody>
                                                    <tr>
                                                        <td><b style={{ fontSize: '22px' }}>{incident.title}</b><br />
                                                            <text style={{ fontSize: '10px' }}>{incident.getCreateTimestampIsoDate()}</text></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {
                                                                (incident != null
                                                                    && incident.getDescription(i18n.language) != null
                                                                    && incident.getDescription(i18n.language).length >= 200) &&
                                                                <div>
                                                                    <text style={{ color: '#CBCBCE' }}>{incident.getDescription(i18n.language).substring(0, 200)}</text>
                                                                </div>
                                                            }
                                                            {
                                                                (incident != null
                                                                    && incident.getDescription(i18n.language) != null
                                                                    && incident.getDescription(i18n.language).length < 200) &&
                                                                <div>
                                                                    <text style={{ color: '#CBCBCE' }}>{incident.getDescription(i18n.language)}</text>
                                                                </div>

                                                            }
                                                            <br />
                                                            <div style={{
                                                                textAlign: 'right',
                                                                border: '2px',
                                                                borderColor: '#CBCBCE'
                                                            }}>
                                                                <a style={{ fontWeight: 'bold', color: '#ECECEC' }}
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setIncident(incident);
                                                                    }}
                                                                    href="#"
                                                                >{t('incidents.list.more')}</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {
                                                                incident.fileName && incident.fileName.map((f: any, index: any) => {
                                                                    if (!isNotImage(f)) {
                                                                        return <img key={`imgkey${f}${index}`}
                                                                            src={`${config.IMAGES_UPLOAD_ENDPOINT}/${f}`}
                                                                            crossOrigin={"anonymous"} height={50} width={50}
                                                                            style={{
                                                                                width: '50%',
                                                                                height: '50%',
                                                                                maxHeight: 100,
                                                                                maxWidth: 100
                                                                            }} />
                                                                    }
                                                                })
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {
                                                                incident.fileName && incident.fileName.map((f: any, index: any) => {
                                                                    if (isNotImage(f)) {
                                                                        return <><a key={`a${f}${index}`} href={`${config.IMAGES_UPLOAD_ENDPOINT}/${f}`}>{incident.originalFileName[index]}</a><br /></>
                                                                    }
                                                                })
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </tr>

                                    </tbody>
                                </Table>
                                {/*</div>*/ }

                            } else {
                                return '';
                            }
                        })}
                    </div>
                </Col>
            </Row>

        </Container>

    )
}

export default IncidentsList;

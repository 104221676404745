import L from "leaflet";
import { Icon } from '@mui/material';
import ReactDOMServer from 'react-dom/server'

const getMarkerStyle = (color: string) => {
    return `
background-color: ${color};
width: 1.6rem;
height: 1.6rem;
display: block;
left: -1.2rem;
top: -1.2rem;
position: relative;
border-radius: 1.5rem 1.5rem 0;
transform: rotate(45deg);
border: 1px solid #FFFFFF`;
}

const getUIIcon = (icon: string) => {
    return <Icon style={{ color: '#fff', transform: 'rotate(-45deg)' }}>{icon}</Icon>
}

const createIcon = (color: string, icon: string) => {
    return L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        popupAnchor: [0, -36],
        html: `<span style="${getMarkerStyle(color ? color : '#fff')}">${ReactDOMServer.renderToString(getUIIcon(icon))}</span>`
    });
}


export default createIcon;
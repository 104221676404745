import { FC, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import Key from '../../models/key.model';
import Multiselect from 'multiselect-react-dropdown';
import './filtr.css';
import keysData from '../../keys.json';



interface FilterProps {
    setFromDateFilter: (e: any) => void;
    setToDateFilter: (e: any) => void;
    setKeysFilter: (e: any) => void;
    setRefreshIncidents: (e: any) => void;
    to: Date;
    from: Date;
    keysFilter: string[];
}

const FilterComponent: FC<FilterProps> = ({ setFromDateFilter, setToDateFilter,
    to, from, setKeysFilter, setRefreshIncidents, keysFilter }) => {

    const [fromDate, setFromDate] = useState(from);
    const [toDate, setToDate] = useState(to);
    const { t } = useTranslation();
    const [keys, setKeys] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);


    const submitFilter = () => {
        setFromDateFilter(fromDate);
        setToDateFilter(toDate);
        setRefreshIncidents(true);
    }

    const getKeys = async () => {
        return keysData;
    }

    useEffect(() => {
        getKeys().then(keys => {
            let oArray: any[] = [];
            let selectedValuesArray: any[] = [];
            keys.forEach((e: Key) => {
                oArray.push({ id: e.identifier, name: e.name });
                if (keysFilter.includes(e.identifier)) {
                    selectedValuesArray.push({ id: e.identifier, name: e.name });
                }
            });
            setKeys(oArray);
            setSelectedValues(selectedValuesArray);

        })

    }, []);

    const onSelect = (selectedList: any, selectedItem: any) => {
        let sArray: string[] = [];
        selectedList.forEach((e: any) => {
            sArray.push(e.id);
        });
        setKeysFilter(sArray);
    }

    const onRemove = (selectedList: any, removedItem: any) => {
        let sArray: string[] = [];
        selectedList.forEach((e: any) => {
            sArray.push(e.id);
        });
        setKeysFilter(sArray);

    }

    return (
        <Container fluid style={{ color: '#fff', textDecorationColor: '#fff' }}>
            <Row>
                <Col md={12}><br /></Col>
            </Row>
            <Row>
                <Col md={6}><text style={{ color: '#ECECEC' }}>{t('incidents.list.date.from')}</text>
                    <DatePicker selected={fromDate} onChange={(date: any) => setFromDate(date)} /></Col>
                <Col md={6}><text style={{ color: '#ECECEC' }}>{t('incidents.list.date.to')}</text>
                    <DatePicker selected={toDate} onChange={(date: any) => setToDate(date)} /></Col>
            </Row>
            <Row>
                <Col md={6} style={{ 'paddingTop': '10px', 'width': '300px' }}>
                    <Multiselect id="keysMultiSelect" displayValue='name' options={keys} selectedValues={selectedValues}
                        onSelect={onSelect} showCheckbox={true} onRemove={onRemove}
                        placeholder={t('filter.selectKey')}
                        customCloseIcon={<></>}

                        style={{
                            option: {
                                color: '#ECECEC',
                                backgroundColor: '#000',
                                background: '#000'
                            },
                            optionContainer: {
                                color: '#ECECEC',
                                backgroundColor: '#000'
                            },
                            chips: { // To change css chips(Selected options)
                                background: '#FBEF50',
                                color: '#000'
                            },
                            multiselectContainer: {
                                color: '#ECECEC',
                                backgroundColor: '#000',
                                
                            },
                            hightlightOptionCheckbox: {
                                background: '#000',
                                color: '#000',
                                borderColor: '#000'
                            }

                        }} />
                </Col>
            </Row>
            <Row>
                <Col md={3}>

                    <Button className="filtr"
                        variant="primary"
                        onClick={submitFilter}
                        style={{
                            backgroundColor: '#FBEF50', 
                            color: '#000',
                            borderColor: '#FBEF50'
                        }}
                    >
                        {t('incidents.list.filter')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <br />
                </Col>
            </Row>
        </Container>

    )
}

export default FilterComponent;
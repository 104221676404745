import { useState, FC, useEffect } from 'react';
import axios from 'axios';
import Key from '../../models/key.model';
import { useTranslation } from 'react-i18next';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { Icon } from '@mui/material';
import keysData from '../../keys.json';

const KeysList: FC = () => {
    const [keys, setKeys] = useState<Key[]>([]);
    const { t } = useTranslation();


    const getAllKeys = async () => {
        return keysData;
    }

    useEffect(() => {
        getAllKeys().then(keys => {
            console.log(keys)
            setKeys(keys);
        });
    }, []);

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <Table bordered style={{color: '#fff', textDecorationColor: '#fff'}}>
                        <thead>
                            <tr>
                                <th>{t('keys.name')}</th>
                                <th>{t('keys.color')}</th>
                                <th>{t('keys.icon')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {keys.map((key: Key) => <tr key={key.identifier}>
                                <td>{key.name}</td>
                                <td>
                                    <div>
                                        <div style={{
                                            width: '36px',
                                            height: '14px',
                                            borderRadius: '2px',
                                            background: `${key.color}`
                                        }} />
                                    </div>
                                </td>
                                <td><Icon>{key.icon}</Icon></td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>

    )
}

export default KeysList;